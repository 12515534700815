import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


const solidIcons = [
  'faEye', 'faPen', 'faTrash',
  'faFileCsv', 'faFileExcel', 
  'faClock', 'faHome', 'faCoffee', 'faDumpster', 'faGraduationCap', 'faBuilding', 
  'faCheck', 'faUndo', 
  'faWindowClose', 'faUser', 
  'faExpandAlt', 'faCompressAlt',
  'faChevronDown',
];

const regularIcons = [
];

const solidRequireComponent = require.context(
  '@fortawesome/free-solid-svg-icons',
  false,
  /\bfa.*\.js$/
)

const regularRequireComponent = require.context(
  '@fortawesome/free-regular-svg-icons',
  false,
  /\bfa.*\.js$/
)


solidRequireComponent.keys().forEach(fileName => {
  if (solidIcons.includes(fileName.replace('./', '').replace('.js', ''))) {
    library.add(solidRequireComponent(fileName).definition);
  }
})

regularRequireComponent.keys().forEach(fileName => {
  if (regularIcons.includes(fileName.replace('./', '').replace('.js', ''))) {
    library.add(requireComponent(fileName).definition);
  }
})


Vue.component('fa-icon', FontAwesomeIcon)
