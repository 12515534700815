<template>
  <vue-editor v-model="v" @input="$emit('input', v)" />
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: 'iRtfField',
  props: ['value'],
  components: {
    VueEditor
  },
  data() {
    return {
      v: this.value
    }
  },
};
</script>

<style>
</style>