/**
 * Accessible from vue through this.$pi
 */

import axios from 'axios'

import store from './store';
import CONFIG from './config';

const YII_VALIDATION_ERROR = 422;
const YII_IGNORED_ERRORS = [YII_VALIDATION_ERROR];

const api = axios.create({ baseURL: CONFIG.api.url });

// for general errors display
api.interceptors.response.use((response) => response, (error) => {
  
  // ignore some errors
  if ( !YII_IGNORED_ERRORS.includes(error.response.status) ) {
    store.state.error = error.response;
  }
  
  return Promise.reject(error);
});

// authentication
api.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token');
  return config;
});


export default api;