<template>
  <span class="i-drop-menu" v-bind="$attrs">
    <fa-icon v-if="icon" :icon="icon" class="idrop-icon"></fa-icon>
    {{label}}
    <fa-icon icon="chevron-down"></fa-icon>
    <div class="i-drop-menu-list">
      <slot></slot>
    </div>
  </span>
</template>


<script>

export default {
  name: 'iDropMenu',
  props: ['label', 'icon']
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

.i-drop-menu {
  position: relative;

  display: flex;
  color: $headerColor;
  align-items: center;
  user-select: none;

  & > .fa-chevron-down {
    font-size: 10px;
    margin: 0 0 0 .5rem;
    opacity: .4;
    width: auto;
  }

  &:hover {
    & > .fa-chevron-down {
      opacity: 0.8;
    }
    & > .i-drop-menu-list {
      display: block;
    }
  }
}

.idrop-icon {
  margin-right: 0.5rem;
  transform: translateY(-1px);
}

.i-drop-menu-list {
  position: absolute;
  z-index: 2;
  background: $headerBgColor;
  top: 100%; left: 0;
  min-width: 100%;
  display: none;
  a {
    border-top: 1px solid #fff1;
    padding: 5px 20px;
    display: block;
    text-align: left;

    &.router-link-exact-active {
      font-weight: bold;
    }
  }
}

</style>
