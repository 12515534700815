import Model from './Model';
const E = (attributes) => Model.extractFields(Collector, attributes);


const Collector = {
    api: '/collectors',
    name: 'Collector',
    attributes: [
      { name: 'id',  type: "integer" },
      { name: 'type',    choices: ['house', 'cafe', 'company', 'collector', 'school'], width: 70 },
      { name: 'label',   label: 'Intitullé', width: 200 },
      { name: 'address', label: 'Adresse', width: 300 },
      { name: 'directions', },
      { name: 'instructions', },
      { name: 'lat', label: 'Latitude', },
      { name: 'lon', label: 'Longitude', },
      { name: 'member.id', },
      { name: 'member.name', label: 'Membre', width: 200},
      { name: 'member.email', label: 'Email', width: 200},
      { name: 'route.label', label: 'Circuit' },
      { name: 'nb_pickups',  type: "integer", label: 'Collectes', width: 70},
      { name: 'disabled', type: 'boolean', },
      { name: 'always', type: 'boolean', label: 'Hebdo' },
      { name: 'assujetti_tva', type: 'boolean',  },
      { name: 'pickup_status', choices: ['done', 'pending', 'canceled'], width: 80 },
      { name: 'first_pickup', type: 'date', width: 90  },
      { name: 'last_pickup', type: 'date', width: 90  },
      { name: 'created_at', width: 80  },
    ],
}

Collector.tableAttributes = E([
  'type',
  'label',
  'address',
  'member.name',
  'member.email',
  'route.label',
  'nb_pickups',
  'pickup_status',
  'created_at',
  'first_pickup',
  'last_pickup',
]);


export default Collector;
