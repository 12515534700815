import Model from './Model';
import view from '@/views/ContactView';

const Contact = {
    api: '/contacts',
    name: 'Contact',
};

Contact.attributes = [
    { 
        name: "type",
        choices: [ 'signup', 'help', 'collector-spotted', 'misc', ],
        width: 80
    },
    { 
        name: "status",
        type: 'string',
        tag: "contact-status",
        choices: ['new', 'spam', 'replied', 'refused', 'accepted'],
        width: 80
    },
    { name: 'name', type: 'string' }, 
    { name: 'company', type: 'string' }, 
    { name: 'address', type: 'string' }, 
    { name: 'member_id', type: 'integer' }, 
    { 
        name: 'phone', 
        type: 'string',
    }, 
    { name: 'subject', type: 'string' }, 
    { name: 'email', type: 'email' }, 
    { name: 'body', type: 'string' }, 
    { name: 'lat', type: 'float' }, 
    { name: 'lon', type: 'float' }, 
    { name: 'created_at', type: 'string' }, 
    { name: 'updated_at', type: 'string' }, 
    { name: 'status_reason', type: 'string' }, 
    { name: 'extra', type: 'string' }, 
];

Contact.tableAttributes = Model.extractFields(Contact, [
    'status', 'name', 'company', 'email', { name: 'subject', width: 300 }, 'created_at', 'lat',
]);

Contact.viewComponent = view;

export default Contact;