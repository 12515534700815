export default {
  data() {
  },
  created() {
  },
  methods: {
    mToString(item) {
      return item.iLabel || item.label || item.name || item.id;
    },
    mIsFiltrable(attribute) {
      if (false === attribute.filter) return false;
      if ('color' === attribute.type) return false;
      return true;
    },
    mFilter(attribute) {
      if ('string' === typeof attribute.filter) 
        return attribute.filter;
      
      if (Array.isArray(attribute.choices))
        return 'exact';

      if (['integer', 'float', 'decimal', 'currency'].includes(attribute.type))
        return 'number';

      return 'like';
    },
    mFilterField(attribute) {
      if (attribute.choices) return 'iSelectField';
      return 'iTextField';
    },
    mType(attribute) {
      if (attribute.type) return attribute.type;
      return 'string';
    }
  },
}