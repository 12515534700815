<template>
  <!-- view -->
  <iModal @close="$emit('close')">
    <template v-slot:header>
      <h1>[{{ item.id }}] {{ mToString(item) }}</h1>
      <div class="actions">
          <iButton icon="pen"   label="Update" @click="$emit('update', item)"></iButton>
          <iButton icon="trash" label="Delete" @click="$emit('delete', item)" confirm="Are you sure ?"></iButton>
      </div>
    </template>
    <iDetailView 
      :item="item" 
      :attributes="model.viewAttributes || model.attributes"
      :showEmpty="false" 
      />
  </iModal>
</template>

<script>
import model from '@/mixins/model';

export default {
  name: "ModelView",
  mixins: [model],
  props: {
    item: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
  },
  data() {
    return {};
  }
};
</script>
