<template>
  <table class="detail-view">
    <tr v-for="(v,k) in data" :key="k">
      <th>{{k}}</th>
      <td v-if="attributes.length">
        <iItemData :item="item" :attribute="getField(k)" />
      </td>
      <td v-else>{{v}}</td>
    </tr>
  </table>
</template>


<script>

import Formatter from '@/helpers/Formatter';

export default {
  name: 'iDetailView',
  props: {
    item: {
      required: true,
      type: Object,
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    showEmpty: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    getField(name) {
      return this.attributes.find(f => f.name=== name);
    }
  },
  computed: {
    data() {
      const d = {};

      // if we have attributes use them
      if (this.attributes.length) {
        for (let attribute of this.attributes) {
          if (this.showEmpty || ![null, undefined].includes(this.item[attribute.name]))
            d[attribute.name] = Formatter.email(this.item[attribute.name]);
        }
        return d;
      }

      // otherwise display all item props
      for (let i in this.item) {
        if (
          (this.showEmpty || (null !== this.item[i] && undefined !== this.item[i]))
          && !this.hide.includes(i)
          ) d[i] = this.item[i];
      }
      return d;
    }
  }
};
</script>

<style scoped lang="scss">
.detail-view {
  width: 100%;
}
th, td {
  border: 1px solid #aaa;
  padding: 10px 6px;
}

th {
  text-align: left;
  white-space: nowrap;
  width: 1px;
}
tr:nth-child(even) {
  background-color: #eee;
}
</style>
