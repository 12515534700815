<template>
  <div>
  </div>
    
  
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
    };
  },
};
</script>

<style scoped lang="scss">
</style>