<template>
  <textarea 
    ref="input" 
    :value="value" 
    @input="$emit('input', $refs.input.value)"
  ></textarea>
</template>

<script>
export default {
  name: 'iTextAreaField',
  props: ['value'],
};
</script>

