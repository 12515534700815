import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

import ModelIndex from '../views/ModelIndex.vue'
import models from '../models';

Vue.use(VueRouter)

const modelRoute = (path, model) => {
  return { 
    path,
    component: ModelIndex, 
    props: { 
      model 
    },
    meta: { requiresAuth: true }, 
  };
}

const routes = [
  {
    path: '/',
    alias: '',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginForm.vue'),
    meta: { requiresGuest: true }
  },
  { 
    path: '/map',
    name: 'map',
    component: () => import('../views/Map.vue'),
    meta: { requiresAuth: true }, 
  },
  { 
    path: '/stats/pickups', 
    name: 'statsPickups',
    component: () => import('../views/stats/Pickups.vue'),
    meta: { requiresAuth: true }, 
  },
  { 
    path: '/members/emails', 
    name: 'emailsList',
    component: () => import('../views/members/Emails.vue'),
    meta: { requiresAuth: true }, 
  },

  modelRoute('/members',    models.Member),
  modelRoute('/collectors', models.Collector),
  modelRoute('/compta',  models.Compta),
  modelRoute('/mail-templates',  models.MailTemplate),
  modelRoute('/memberships',  models.Membership),
  modelRoute('/mails-sent',  models.MailSent),
  modelRoute('/pickups',  models.Pickup),
  modelRoute('/contacts', models.Contact),
  modelRoute('/pickups', models.Pickup),
  modelRoute('/news',     models.News),
  modelRoute('/routes',   models.Route),
]

const router = new VueRouter({
  mode: 'history',
  routes
})


// Add security
router.beforeEach((to, from, next) => {
      // secure 
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('access_token') === null) {
      next({ path: '/login', query: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  }
  else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (localStorage.getItem('access_token') !== null) {
      next({ path: '/', query: { nextUrl: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
