<template>
  <iModal @close="$emit('close')" class="error">
    <template slot="header">Une erreur est survenue</template>
    <div class="msg">
      {{text}}<br>
      <pre v-if="showDetails">{{error}}</pre>
      <iButton v-else @click="showDetails = true" label="Show Details">Show Details</iButton>
    </div>
  </iModal>
</template>


<script>

export default {
  name: 'iError',
  props: {
    error: { },
    message: { type: String, default: null },
  },
  data() {
    return {
      showDetails: null,
    }
  },
  beforeMount() {
    if (!this.text) this.showDetails = true;
  },
  computed: {
    text() {
      if (this.message) return this.message;

      if (this.error && this.error.data && this.error.data.code) {
        switch (this.error.data.code) {
          case 23000 : return "L'opération n'est pas possible à cause d'une contrainte de la base de donnée";
        }
      }

      if (this.error && this.error.data && this.error.data.message) {
        return this.error.data.message;
      }
    }
  }
};
</script>

<style scoped lang="scss">

@import "@/assets/sass/variables";

.modal-wrap.error {
  z-index: 2000;
}

.msg {
  padding: $gap;
}

pre {
  padding: 4px;
  background-color: black;
  color: #03be03;
  width: fit-content;
}

button {
  margin-top: $gap;
  background: #b10b0b;
}

</style>
