


const Dates = {};

Dates.format = (format = 'Y-m-d', date) => {
    if (!date) date = new Date();

    return date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
}


export default Dates;