
const Browser = {};

Browser.isMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

Browser.download = (data, filename) => {
    const a = document.createElement('a');
    a.setAttribute("href", encodeURI(data));
    a.setAttribute("download", filename);
    a.click();
}

export default Browser;