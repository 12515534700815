<template>
  <fa-icon :icon="icon" @click="toggle" :class="{ checked: !!v, unchecked: !v }"></fa-icon>
</template>

<script>
export default {
  name: 'iCheckboxIconField',
  props: ['value', 'icon'],
  data() {
    return {
      v: this.value
    }
  },
  methods: {
    toggle() {
      this.v = !this.v;
      this.$emit('input', this.v);
    }
  }

};
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
}
.unchecked {
  opacity: 0.35;
}
</style>