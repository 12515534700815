<template>
  <iMenu id="main-menu">
    <router-link class="brand" to="/"><b>T</b>unisie <b>R</b>ecyclage</router-link>
    <iDropMenu label="Collectes">
      <router-link to="/map">Carte</router-link>
      <router-link to="/collectors">Points de Collecte</router-link>
      <router-link to="/pickups">Historique des Collectes</router-link>
    </iDropMenu>
    <iDropMenu label="Membres">
      <router-link to="/members">Liste</router-link>
      <router-link to="/members/emails">Emails</router-link>
    </iDropMenu>
    <iDropMenu label="Messagerie">
      <router-link to="/contacts">Formulaires Remplis</router-link>
      <router-link to="/mails-sent">Emails Envoyés</router-link>
      <router-link to="/mail-templates">Templates Mails</router-link>
      <router-link to="/survey">X Formulaires de Satisfaction</router-link>
    </iDropMenu>
    <iDropMenu label="Compta">
      <router-link to="/compta">Entrées / Sorties</router-link>
      <router-link to="/memberships">Abonnements</router-link>
    </iDropMenu>
    <router-link to="/news">News</router-link>
    <iDropMenu label="Stats">
      <router-link to="/stats/pickups">Ramassages par semaine</router-link>
    </iDropMenu>
    <iDropMenu label="Admin">
      <router-link to="/routes">Circuits</router-link>
    </iDropMenu>
    <router-link v-if="!$store.state.user" class="end" to="/login">Login</router-link>
    <iDropMenu v-else icon="user" class="end" :label="$store.state.user.name">
      <a href="#" @click.prevent="logout" title="store.state.user.name">Logout</a>
    </iDropMenu>
  </iMenu>
</template>


<script>

export default {
  name: 'MainMenu',
  methods: {
    logout() {
      this.$store.commit('logOut');
      this.$router.push('/');
    }
  }
}

</script>



<style lang="scss" scoped>
@import "@/assets/sass/variables";

</style>