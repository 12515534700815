<template>
  <form class="iForm" @submit.prevent="submit">
    <main v-grid="2">
        <iField 
          v-for="(attribute,k) in myFields" :key="k" 
          :attribute="attribute" 
          v-span="attribute.span"
          v-model="model[attribute.name]" 
          :error="getError(attribute.name)" />
    </main>
    <footer>
      <iButton type="submit"  icon="check" label="Soumettre"></iButton>
      <iButton @click="reset" icon="undo"  label="Annuler"></iButton>
    </footer>
  </form>
</template>


<script>

export default {
  name: 'iForm',
  props: {
    item:   { type: Object, required: true },
    attributes: { type: Array },
    errors: { type: Array, default: () => [] },
  },
  data() {
    let myFields = this.attributes;
    if (!myFields) {
      myFields = [];
      Object.keys(this.item).forEach(key => myFields.push({ name: key, type: "string" }));
    } 

    return {
      model: { ...this.item },
      myFields
    }
  },
  methods: {
    reset() {
      this.model = { ...this.item };
      this.errors = [];
    },
    submit() {
      this.$emit('submit', this.model);
      return false;
    },
    getError(attribute) {
      return this.errors.find(err => err.attribute=== attribute)
    }
  },
  computed: {
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

.iForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #eee;
}

footer {
  background: #fff;
}

main, footer {
  padding: $gap;
}

footer {
  display: flex;
  gap: $gap;
  margin-top: auto;
  border-top: 1px solid #ccc;


}


</style>
