
const Route = {
    api: '/routes',
    name: 'Route',
    attributes: [
        { name: "label", type: 'string', label: "Nom", width: 200 },
        { name: "day", type: 'integer', label: "Jour", width: 200 },
        { name: "hours", type: 'string', label: "Heures", width: 200 },
        { name: "color", type: "color", width: 100 },
    ],    
}

export default Route;