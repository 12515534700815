const Helper = {};

Helper._uid = 1;
Helper.uid = () => Helper._uid++;

Helper.get = (obj, path, def) => {
    if (!obj) return def || null;
    const attributes = path.split('.');
    let o = obj;
    for (let i=0; i<attributes.length; i++) {
        if (undefined === o || null === o) return (undefined === def) ? null : def;
        o = o[attributes[i]];
    }
    if (undefined === o || null === o) return (undefined === def) ? null : def;
    return o;
}

export default Helper;