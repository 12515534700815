import Collector from "./Collector";
import Compta from "./Compta";
import Contact from "./Contact";
import MailSent from "./MailSent";
import MailTemplate from "./MailTemplate";
import Member from "./Member";
import Membership from "./Membership";
import News from "./News";
import Pickup from "./Pickup";
import Route from "./Route";


export {
    Collector,
    Compta,
    Contact,
    MailSent,
    MailTemplate,
    Member,
    Membership,
    News,
    Pickup,
    Route,
};

export default {
    Collector,
    Compta,
    Contact,
    MailSent,
    MailTemplate,
    Member,
    Membership,
    News,
    Pickup,
    Route,
};
