<template>
  <input 
    type="email" 
    ref="input" 
    :value="value" 
    @input="$emit('input', $refs.input.value)"
  >
</template>

<script>
export default {
  name: 'iEmailField',
  props: ['value'],
};
</script>

