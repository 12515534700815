import Model from './Model';
const E = Model.extractFields;

// @property int $mail_template_id
// @property int $member_id
// @property int $contact_id

const MailSent = {
    api: '/mail-sents',
    name: 'MailSent',
    attributes: [
        { name: 'id', type: 'integer' },
        { name: 'email', type: 'email' },
        { name: 'subject' },
        { name: 'body', field: 'iRtfField' },
        { 
            name: 'status',
            choices: ['sent', 'pending', 'failed', 'canceled', 'draft'],
            tag: 'mail-status',
        },
        { name: 'created_at', type: 'datetime' },
        { name: 'sent_at', type: 'datetime' },
        { name: 'obsolete', type: 'boolean' },
        { name: 'bulk', type: 'integer' },
    ],    
}

MailSent.tableAttributes = E(MailSent, [
    'id', 'email', 'subject', 'created_at', 'sent_at', 'status',
]);


export default MailSent;


