<template>
  <input 
    type="text" 
    ref="input" 
    :value="value" 
    :placeholder="placeholder"
    @input="$emit('input', $refs.input.value)"
  >
</template>

<script>
export default {
  name: 'iTextField',
  props: ['value', 'placeholder'],
};
</script>

