
/**
 * Value Formatter in html
 */


const F = {};


F.color = (val) => {
    return `<span style="background-color: ${val}; color: white; display: inline-block; padding: 1px 4px;">${val}</span>`;
}

F.email = val => `<a href="mailto:${val}">${val}</a>`;
F.url   = val => `<a href="${val}" target="_blank">${val}</a>`;

F.currency = (val) => {
    val = val.toFixed(3).replace('.', '<span style="opacity: 0.7">.') + '</span>'; 
    return val;
}

F.phone = (val) => {
    const m = val.match(/^(\d{2})(\d{3})(\d{3})$/);
    if (m) {
        return `<a href="tel:${val}">` + [m[1], m[2], m[3], m[4]].join(' ') + '</a>';
    }
    return val;
}

F.tag = (val, cat, label) => `<span class="tag tag-${cat || 'tag'}-${val}">${label || val}</span>`;

F.date = (val) => {
    return val.replace(/ \d\d:\d\d:\d\d/, '');
}

export default F;
