<template>
  <div class="modal-wrap" ref="wrap" @click.self="$emit('close')">
    <div class="modal" :class="{ fullscreen }">
      <header>
        <slot name="header"></slot>
        <iButton :icon="(fullscreen ? 'compress' : 'expand') + '-alt'" size="2x" class="fullscreen" title="Plein écran" @click="fullscreen = !fullscreen" />
        <iButton icon="window-close" size="2x" class="close-icon" title="Fermer" @click="$emit('close')" />
      </header>
      <main>
        <slot></slot>
      </main>
      <!--
      <footer>
        <slot name="footer">
          <iButton @click="$emit('close')" label="Close"></iButton>
        </slot>
      </footer>
      -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'iModal',
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

.modal-wrap {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  // display: grid; place-items: center; does not work for long conten
  display: flex; 
  justify-content: center;
  align-content: center;
  background-color: rgba(0,0,0,0.6);

  &.error {
    background-color: rgba(60,0,0,0.6);
  }
}

.modal {
  position: relative;
  z-index: 1001;
  width: 600px;
  max-height: 80%;
  margin: auto;
  box-shadow: 0 0 8px -2px black;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  &.fullscreen {
    position: fixed;
    inset: 0.5rem;
    width: initial;
    max-height: initial;
  }


  & > main {
    overflow: auto;
  }

  & > header {
    padding: 10px $gap;
    border-bottom: 1px solid #888;
    align-items: center;
    display: flex;
    h1 {
      font-size: 1.2rem;
      margin: 0;
    }

    .fullscreen {
      margin-left: auto;
      margin-right: 0.5rem;
    }
  }
}

.error > .modal > header {
  background-color: #b10b0b;
}

.error > .modal {
    background-color: #770b0a;
    color: white;
}


</style>
