import localConfig from "./config.local";

const config = {
    appName: 'TunisieRecyclage',
    api: {
      url: 'https://api.tunisierecyclage.org',
    },
}

export default Object.assign({}, config, localConfig);
