<template>
  <div>
    <iDetailView :item="item" :attributes="[{field: 'name'},{field: 'subject'}]"></iDetailView>
    <div>The map</div>
  </div>
</template>

<script>
export default {
  props: ['item'],
}
</script>

<style>

</style>