import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: false,
    user: JSON.parse(localStorage.getItem('user')),
    breadCrumbs: {},
  },
  mutations: {
    logIn (state, { access_token, user }) {
      state.user = user;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('user', JSON.stringify(user));
    },
    logOut (state) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      state.user = null;
    },
  },
  actions: {
  },
  modules: {
  },
});
