import Model from './Model';
const E = (attributes) => Model.extractFields(News, attributes);


const News = {
    api: '/news',
    name: 'News',
    attributes: [
        { name: "published_at", type: 'datetime', width: 100 },
        { name: "title", type: 'string', width: 200 },
        { name: "body", type: 'string', field: 'iTextAreaField', width: 400, span: '1 / 3' },
        { name: "long_body", type: 'string', width: 400, field: 'iRtfField', span: '1 / 3' },
        { name: "link", type: "url", width: 180 },
    ],
    defaultSort: '-published_at',
}

News.tableAttributes = E([
    'published_at', 'title', 'body', 'link'
]);

export default News;