import Model from './Model';
const E = Model.extractFields;

const Membership = {
    api: '/memberships',
    name: 'Membership',
    attributes: [
        { name: 'id', type: 'integer' },
        { name: 'starts_on', type: 'date' },
        { name: 'ends_on', type: 'date' },
        { name: 'price', type: 'currency' },
        { name: 'normal_price', type: 'currency' },
        { name: 'discount_reason', type: 'string' },
    ],    
}


export default Membership;


