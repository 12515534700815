import Model from './Model';
const E = Model.extractFields;

const Member = {
    api: '/members',
    name: 'Member',
    attributes: [
        { name: 'id', type: 'integer' },
        { name: 'name', label: 'Nom', width: 200 },
        { name: 'email', type: 'email', width: 200 },
        { name: 'phone', type: 'phone', width: 200 },
        { name: 'url', type: 'url' },
        { name: 'memberships_price', type: 'currency', label: 'Abonnements', width: 100 },
        { name: 'total_cotisations', type: 'currency', label: 'Cotisations', width: 100 },
        { name: 'due', type: 'currency', width: 100 },
        { name: 'title', type: 'string' },
        { name: 'comments', type: 'string' },
        { name: 'password', type: 'string' },
        { name: 'remember_me', type: 'integer' },
        { name: 'roles', type: 'string' },
        { name: 'status', type: 'string', choices: ['enabled', 'disabled'], },
        { name: 'dashboard', type: 'string' },
        { name: 'created_at', type: 'string' },
        { name: 'updated_at', type: 'string' },
        { name: 'last_seen_at', type: 'string' },
        { name: 'last_activity', type: 'string' },
        { name: 'mailchimp_id', type: 'integer' },
        { name: 'mailchimp_status', type: 'string' },
    ],
};

Member.process = (item) => {
    item.due = parseFloat(item.memberships_price || 0) - parseFloat(item.total_cotisations || 0);
};

Member.tableAttributes = E(Member, [
    'name', 'email', 'phone', 'memberships_price', 'total_cotisations', 'due', 'status', 'mailchimp_status'
]);

Member.csvAttributes = E(Member, [
    'name', 'email', 'phone', 'memberships_price', 'total_cotisations', 'due', 'status', 'mailchimp_id', 'mailchimp_status',
]);

Member.viewAttributes = E(Member, [
    'name', 'email', 'phone', 'memberships_price', 'total_cotisations', 'due', 'mailchimp_id', 'mailchimp_status',
]);

Member.editAttributes = E(Member, [
    'name', 'email', 'phone', 'title', 'url',
    { name: 'status', field: 'iRadiosField' },
    { name: 'comments', field: 'iTextAreaField', span: '1 / 3'},
]);

export default Member;


