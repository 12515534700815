<template>
  <div class="i-radios-field-input">
    <label v-for="o in options" :key="o">
      <input 
        type="radio"
        ref="input"
        :name="name"
        :value="o"
        :checked="o === value"
        @change="change($event)">&nbsp;{{o}}
    </label>
  </div>
</template>

<script>

import Helper from '@/helpers/Helper';

export default {
  name: 'iRadiosField',
  props: ['value', 'options'],
  data() {
    return {
      name: 'name' + Helper.uid(),
    }
  },
  methods: {
    change(event) {
      for (let input of this.$refs.input) {
        if (input.checked) return this.$emit('input', input.value);
      }
    }
  }
};
</script>

