import api from '@/api';

const Model = {};


/**
 * Creates a attributes object from a global one
 */
Model.extractFields = (model, attributes) => {
    const res = [];

    for (let f of attributes) {
        // if simple string make it complicated
        if ('string' === typeof f) f = { name: f };

        // enhance ?
        const def = model.attributes.find(i => i.name === f.name);
        if (def) {
            res.push( {...def, ...f });
        } else {
            console.error('Missing model definition of the attribute: ' + f.name);
        }
    }

    return res;
}

Model.loadAll = (model) => {
    return api.get(model.api).then(j => {
        if (model.process)
            j.data.forEach(model.process);

        return j;
    });
}

Model.loadOne = (model, id) => {
    return api.get(`${model.api}/${id}`).then(j => {
        if (model.process)
            model.process(j.data);

        return j;
    });
}

Model.saveOne = (model, id, data) => {
    return api.put(`${model.api}/${id}`, data).then(j => {
        if (model.process)
            model.process(j.data);

        return j;
    });
}

export default Model;