import Model from './Model';
const E = Model.extractFields;

const Pickup = {
    api: '/pickups',
    name: 'Pickup',
    attributes: [
        { name: 'id', type: 'integer' },
        { name: 'collector_id', type: 'integer' },
        { name: 'membership_id', type: 'integer' },
        { name: 'created_at', type: 'date' },
        { 
            name: 'status', 
            choices: ['done', 'pending', 'canceled'],
            tag: 'pickup-status' 
        },
    ],    
}


export default Pickup;


