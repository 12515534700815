<template>
  <div class="iField">
    <label v-if="label !== false">{{label || attribute.label || attribute.name}}</label>
    <component :is="is" v-model="v" @input="input" v-bind="bind" :name="name" :placeholder="placeholder" />
    <div class="error" v-if="error">{{error.message}}</div>
  </div>
</template>


<script>

import iFields from '@/components/widgets/fields';
import Strings from '@/helpers/Strings';
import model from '@/mixins/model';

export default {
  name: 'iField',
  props: ['attribute', 'field', 'value', 'error', 'allowEmpty', 'label', 'name', 'placeholder'],
  mixins: [model],
  components: { 
    ...iFields
  },
  data() {
    return {
      v: this.value,
    };
  },
  methods: {
    input() {
      this.$emit('input', this.v);
    }
  },
  computed: {
    is() {

      if ('string' === typeof this.field) {
        return iFields[this.field];
      }

      if (this.attribute) {
        if (this.attribute.field) {
          return iFields[this.attribute.field];
        }
  
        if (this.attribute.choices) {
          return iFields.iSelectField;
        }
  
        const type = this.mType(this.attribute);
  
        if ('string' === type) {
          return iFields.iTextField;
        }
  
        if (['url', 'color', 'date', 'phone', 'email'].includes(type)) {
          return iFields['i' + Strings.capitalize(type) + 'Field'];
        }
  
        if ('text' === type) {
          return iFields.iTextAreaField;
        }
      }

      return iFields.iTextField;
    },
    bind() {
      if (this.attribute && this.attribute.choices) {
        return {
          allowEmpty: this.allowEmpty,
          options: this.attribute.choices
        };
      }
      return {}
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";


.iField {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.error {
  color: #c31515;
  font-style: italic;
}

</style>
