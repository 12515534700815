import Vue from 'vue';

const fields = {};

const requireComponent = require.context(
    // The relative path of the components folder
    './',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /\bi[A-Z]\w+\.vue$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)

    // Get PascalCase name of component
    const componentName = 
        fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '');


    fields[componentName] = componentConfig.default || componentConfig;
})

export default fields;