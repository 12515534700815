import Browser from './Browser';
import Converter from './Converter';
import Dates from './Dates';
import Formatter from './Formatter';
import Helper from './Helper';
import Strings from './Strings';

const Helpers = {
    Browser,
    Converter,
    Dates,
    Formatter,
    Helper,
    Strings,
}

export default Helpers;