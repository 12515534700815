<template>
  <div class="i-grid-view-wrapper">
  <div class="i-grid-view">

    <!-- resize handles -->
    <div class="resizers">
      <div 
        v-for="(w,i) in widths.cols" :key="`res-${i}`" 
        :style="{ left: widths.resizers[i] + 'px'}"
        draggable
        @dragstart="resizeDragStart($event, i)"
        @drag="resizeDrag($event, i)"
        @dragend="resizeDragEnd($event, i)"
        ></div>
    </div>

    <!-- labels row -->
    <header class="row labels" :style="colStyle">

      <!-- main actions -->
      <div class="cell actions">
        <iButton icon="file-csv" @click="download('csv')"  color="white" title="CSV"></iButton>
        <iButton icon="file-excel" @click="download('xlsx')" color="white" title="Excel"></iButton>
      </div>
      <div 
        v-for="(f,i) in tableAttributes" :key="`lab-${i}`" 
        @click="sortBy(f.name)"
       :class="labelClass(f.name, i)"
       class="cell label"
      >
        <span>{{f.label || f.name}}</span>
      </div>
    </header>

    <!-- filters row -->
    <header class="row filters" :style="colStyle">
      <div class="cell"><input v-if="checkboxes" type="checkbox"></div>
      <div v-for="(f,i) in tableAttributes" :key="`fil-${i}`" class="cell" :class="{ hasValue: !!filters[f.name] }">
        <iField 
          v-if="mIsFiltrable(f)"
          :label="false"
          :attribute="f"
          :field="mFilterField(f)" 
          :allowEmpty="true"
          v-model="filters[f.name]" 
          />
      </div>
    </header>

    <!-- no result message -->
    <div v-if="items.length && !filteredItems.length" class="warning big">
        Aucun résultat
    </div>


    <slot name="prepend"></slot>

    <!-- data rows -->
    <RecycleScroller class="data-rows" 
      :items="sortedItems" :item-size="rowHeight" key-field="id" v-slot="{ item, index }" >
      <div class="row" :class="{even: index%2}" :style="colStyle">
        <div class="cell actions">
          <input v-if="checkboxes" type="checkbox">
          <iButton icon="eye"  color="blue" @click="$emit('itemclicked', item)"></iButton>
          <iButton icon="pen"  color="blue" @click="$emit('itemupdateclicked', item)"></iButton>
          <iButton icon="trash" color="red"  @click="$emit('itemdeleted', item)" confirm="Are you sure ?"></iButton>
        </div>
        <div v-for="(f,i) in tableAttributes" :key="i"  :class="dataClass(item, f)" class="cell">
            <template v-if="hasSlot(f.name)">
              <slot :name="f.name" :item="item"></slot>
            </template>
            <template v-else>
              <iItemData :item="item" :attribute="f"></iItemData>
            </template>
          </div>
      </div>
    </RecycleScroller>

    <footer>
      {{filteredItems.length}} items. Filters {{filters}} 
    </footer>
  </div>
  </div>
</template>


<script>

import Vue from 'vue';
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
Vue.use(VueVirtualScroller)
import model from '@/mixins/model';

import Helpers from "@/helpers";
const { Helper, Strings, Converter, Browser, Dates } = Helpers;

export default {
  name: 'iGridView',
  mixins: [model],
  props: {
    items: { type: Array },
    model: { type: Object },
    attributes: { type: Array },
    checkboxes: { type: Boolean, default: true },
    defaultSort: { type: String },
    rowHeight: { type: Number, default: 29 },
  },
  data() {
    return {
      filters: {},
      sortField: null,
      sortDir: 1,
      widths: {
        initial : [],
        cols: [],
        resizers: [],
        index: null,
        offset: 0,
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.filters = {};
      this.widths.cols = [];
      // col sizes
      let store = localStorage.getItem('iGridView.cols');
      if (store) {
        store = JSON.parse(store);
        if (store[this.$route.path] && ( (store[this.$route.path].length + 1) === this.tableAttributes.length)) {
          this.widths.cols = [...store[this.$route.path]];
          this.widths.initial = [...store[this.$route.path]];
        }
      }

      if (!this.widths.cols.length) {
        this.widths.cols = [this.checkboxes ? 74 : 54, ...this.tableAttributes.map(f => f.width || 150 )];
        this.widths.initial = [this.checkboxes ? 74 : 54, ...this.tableAttributes.map(f => f.width || 150 )];
      }

      this.widths.resizers = this.getResizers(this.widths.cols);

      // default sorting
      if (this.defaultSort) {
        if (this.defaultSort[0] === '-') this.sortDir = -1;
        this.sortField = this.defaultSort.replace(/^-/, '');
      }
    },
    sortBy(attribute) {
      this.sortDir = this.sortField === attribute ? -this.sortDir : 1;
      this.sortField = attribute;
    },
    getResizers(cols) {
      let total = 0;
      const pos = [];
      for (let i=0; i<=cols.length; i++) {
        pos.push(total+= cols[i]);
      }
      return pos;
    },
    resizeDragStart(e, index) { 
      this.widths.index = index;
      requestAnimationFrame(this.doResize);
    },
    doResize() {
      const W = this.widths;
      if (W.index) {
        this.$set(
          W.cols, 
          W.index, 
          Math.max(20, W.initial[W.index] + W.offset)
        ); 
        requestAnimationFrame(this.doResize);
      }
    },
    labelClass(attribute, x) {
      const s = {};
      if (this.sortField === attribute) s[this.sortDir === 1 ? 'sort_asc' : 'sort_desc'] = true;
      return s;
    },
    resizeDrag(e) { 
      this.widths.offset = e.offsetX;
    },
    resizeDragEnd(e) {
      this.widths.index = null;
      this.widths.offset = 0;
      this.widths.initial = [...this.widths.cols];
      this.widths.resizers = this.getResizers(this.widths.cols);
      
      let store = localStorage.getItem('iGridView.cols');
      store = store ? JSON.parse(store) : {};
      store[this.$route.path] = this.widths.cols;
      localStorage.setItem('iGridView.cols', JSON.stringify(store));
    },
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    dataClass(item, attribute) {
      return `model-${this.model.name} attr-${attribute.name} type-${this.mType(attribute)}`;
    },
    download(format) {
      Converter.to[format](
        this.sortedItems, 
        (this.model && (this.model.csvAttributes || this.model.attributes)) || this.tableAttributes,
        (this.model.name || 'export') + '-' + Dates.format("Y-m-d") + '.' + format
      );
    }
  },
  computed: {
    colStyle() {
      return {
        "grid-template-columns": this.widths.cols.map(w => `${w}px`).join(' '),
        height: this.rowHeight + 'px',
      }
    }, 
    filteredItems() {
      // console.time('filtering...');

      const items = [];

      // create filters
      const filters = [];
      for (let attribute in this.filters) {
        const value = this.filters[attribute];
        if (value !== '') {
          
          // type of filter (strict, like ...)
          let f = this.tableAttributes.find(f => f.name === attribute);
          let type = this.mFilter(f);
          
          // if number are we comparing ?
          if ('number' === type) {
            const m = value.match(/(>|<)?\s*(\d+)/);
            if (!m) continue;
            if (m[1] !== undefined) type = m[1];
            value = parseFloat(m[2]);
          }

          filters.push({ 
            type, 
            name: attribute, 
            value, 
          });
        }
      }

      // filter items
      this.items.forEach(item => {
        let pass = true;

        for (let i=0; i<filters.length; i++) {
          const filter = filters[i];
          
          switch (filter.type) {
            case 'exact':
              if (Helper.get(item, filter.name) != filter.value) { pass = false; break; }
              break;
            case 'number':
              if (parseFloat(Helper.get(item, filter.name)) != filter.value) { pass = false; break; }
              break;
            case '>':
              if (parseFloat(Helper.get(item, filter.name)) < filter.value) { pass = false; break; }
              break;
            case '<':
              if (parseFloat(Helper.get(item, filter.name)) >= filter.value) { pass = false; break; }
              break;
            case 'like':
              if (!Strings.search(filter.value, Helper.get(item, filter.name))) { pass = false; break; }
              break;
          }
        }

        if (pass) items.push(item);
      })
      // console.timeEnd('filtering...');
      return items;
    },
    sortedItems() {
      if (this.sortField) {
        const attribute = this.tableAttributes.find(f => f.name=== this.sortField);
        if (attribute) {
          if (['number', 'integer', 'float', 'decimal', 'currency'].includes(this.mType(attribute))) {
            return [...this.filteredItems].sort( (a,b) => {
              let A = Helper.get(a, this.sortField);
              let B = Helper.get(b, this.sortField);
              if (null === A) A = this.sortDir * Infinity; 
              if (null === B) B = this.sortDir * Infinity; 
              return  parseFloat(A) > parseFloat(B) ? this.sortDir : -this.sortDir;
            });
          }
  
          return [...this.filteredItems].sort( (a,b) => {
              let A = Helper.get(a, this.sortField);
              let B = Helper.get(b, this.sortField);
              return Strings.sort(A, B, this.sortDir);
          });
        }       
      }

      return this.filteredItems;
    },
    tableAttributes() {
      const attributes = this.attributes || this.model.tableAttributes || this.model.attributes; 
      return attributes;
    },
  },
  watch: {
    attributes() {
      this.sortField = null;
      this.init();
    },
    model() {
      this.sortField = null;
      this.init();
    },
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";


</style>
