import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '@/components/widgets';
import './fa';

Vue.config.productionTip = false;

import api from './api';
Vue.prototype.$api = api;

import '@/assets/sass/main.scss';

Vue.directive('grid', {
  bind: function (el, { value }) {
    el.classList.add('grid');
    el.classList.add('grid-' + value);
    el.style.gridTemplateColumns = `repeat(${value}, 1fr)`;
  }
})

Vue.directive('span', {
  bind: function (el, { value }) {
    if (value) {
      el.style['grid-column'] = value;
    }
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
