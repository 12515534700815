<template>
  <select ref="input" :value="value" @change="$emit('input', $refs.input.value)">
    <option v-if="allowEmpty"></option>

    <template v-if="isGrouped">
      <optgroup v-for="(group, gid) in myOptions" :key="gid" :label="gid">
        <option v-for="o in group" :key="o.value" :value="o.value">
          {{o.label}}
        </option> 
      </optgroup>
    </template>

    <template v-else>
      <option v-for="o in myOptions" :key="o.value" :value="o.value">
        {{o.label}}
      </option> 
    </template>
  </select>
</template>

<script>
export default {
  name: 'iSelectField',
  props: ['value', 'options', 'allowEmpty'],
  computed: {
    isGrouped() {
      if (Array.isArray(this.options)) return false;
      return 'object' === typeof Object.values(this.options)[0];
    },
    myOptions() {

      if (this.isGrouped) {
        const res = {};

        Object.entries(this.options).forEach( ([gid, group]) => {
          if (Array.isArray(group)) {
            res[gid] = group.map(e => ({ value: e, label: e }))
          } else {
            res[gid] = Object.entries(group).map( ([value, label]) => ({ value, label }) );
          }
        })

        return res;
      } else {
        if (Array.isArray(this.options)) {
          return this.options.map(e => ({ value: e, label: e }))
        } else {
          return Object.entries(this.options).map( ([value, label]) => ({ value, label }) );
        }
      }

      console.log(res);
    }
  }
};
</script>

