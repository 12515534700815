<template>
  <span v-if="html"
    :class="`data attr-${attribute.name} type-${mType(attribute)}`" 
    v-html="html" />
</template>


<script>

import Helper from "@/helpers/Helper";
import Formatter from "@/helpers/Formatter";
import model from '@/mixins/model';

export default {
  name: 'iItemData',
  mixins: [model],
  props: {
    item:  { type: Object, required: true },
    attribute: { },
  },
  data() { return {}; },
  computed: {
    html() {

      // raw value
      let val = Helper.get(this.item, this.attribute.name || this.attribute);
      if (null === val) return '';

      // for choices that are objects we might have lables
      if (this.attribute.choices && !Array.isArray(this.attribute.choices)) {

        const choices = this.attribute.choices;

        // simple object
        if (choices[val] && 'object' !== typeof choices[val]) {
          val = choices[val];
        } else {
          for (let k in choices) {
            if (choices[k][val]) {
              val = choices[k][val];
              break;
            }
          }
        }
      }


      // formatters
      const { type, tag } = this.attribute;

      if (tag)
        return Formatter.tag(val, tag);

      if (Formatter[type])
        return Formatter[type](val);

      return val;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

</style>
