<template>
  <select class="i-select" ref="select" :value="value" @input="oninput">
    <option :value="null"></option>
    <option v-for="(item, i) in items" :key="i" :value="item">{{item}}</option>
  </select>
</template>

<script>
export default {
  name: 'iSelect',
  props: ['value', 'items'],
  methods: {
    oninput() {
      this.$emit('input', this.$refs.select.value);
    }
  }
};
</script>

<style scoped lang="scss">
</style>
