<template>
  <nav class="iMenu">
    <slot></slot>
  </nav>
</template>


<script>

export default {
  name: 'iMenu',
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

nav {
  position: absolute;
  top: 0; left: 0; right: 0;
  height: $mainHeaderHeight;
  background: $headerBgColor;
  display: flex;
  color: $headerColor;
  align-items: center;
  user-select: none;

  & > * {
    padding: 0 20px;
    height: 100%;
    display: flex;
    place-items: center;
    &:last-child {
      border-right: 0;
    }
    &.end {
      margin-left: auto;
    }

    &:hover,
    &:hover ::v-deep .i-drop-menu-list {
      background: #455694;
    }
  }

  ::v-deep .i-drop-menu-list a {
    display: block;
    white-space: nowrap;


    &.brand {
      font-size: 21px;
      font-family: "Open Sans";
      letter-spacing: -1px;
      font-weight: 100;
    }


    &.router-link-exact-active {
      font-weight: bold;
    }
  }
}
nav ::v-deep .i-drop-menu.end .i-drop-menu-list {
  left: initial;
  right: 2px;
} 
</style>
