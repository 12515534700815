import Model from './Model';
const E = Model.extractFields;

const MailTemplate = {
    api: '/mail-templates',
    name: 'MailTemplate',
    attributes: [
        { name: 'id', type: 'integer' },
        { name: 'label', label: 'Intitulé', width: 200 },
        { name: 'subject', width: 200 },
        { name: 'body', field: 'iRtfField', width: 200 },
        { name: 'extra', type: 'string' },
        { name: 'system', type: 'boolean' },
        { name: 'obsolete', type: 'boolean' },
    ],    
}

MailTemplate.tableAttributes = E(MailTemplate, [
    'id', 'label', 'subject', 'obsolete', 'system',
]);

MailTemplate.editAttributes = E(MailTemplate, [
    'label', 'subject', 'extra', 'obsolete', 'system',
]);

export default MailTemplate;


