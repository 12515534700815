
/**
 * String helper functions
 */

const Strings = {};

Strings.stripAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

Strings.capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);


/**
 * Check wether a string is a number ex: "654.21"
 * 
 * @param {*} str 
 * @return Boolean
 */
Strings.isNumber = (str) => str.match(/^[+-]?\d+(?:\.\d+)?$/);

Strings.copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.style.opacity = 0; el.style.position = 'absolute'; el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

Strings.search = (words, sentence) => {
    if ("string" !== typeof words) return false;
    if ("string" !== typeof sentence) return false;
    if (-1 !== sentence.indexOf(words)) return true;
    words = Strings.stripAccents(words.toLowerCase());
    sentence = Strings.stripAccents(sentence.toLowerCase());
    
    words = words.replace(/\s+/g, ' ');
    words = words.split(' ');
    for (let w of words) {
        if (-1 === sentence.indexOf(w)) return false;
    }
    return true;
};

Strings.sort = (A, B, sortDir) => {
    if (null === A) A = sortDir === 1 ? String.fromCharCode(1000) : '';
    else A = String(A).trim().toLowerCase();
    if (null === B) B = sortDir === 1 ? String.fromCharCode(1000) : ''; 
    else B = String(B).trim().toLowerCase();
    return A > B ? sortDir : -sortDir;
}

export default Strings;
