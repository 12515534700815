<template>
  <button @click="click" class="i-button" :class="{ icon: !!icon, label: !!label }">
    <fa-icon v-if="icon" :icon="icon" :color="color" :size="size"></fa-icon>
    <span v-if="label">{{label}}</span>
  </button>
</template>


<script>

export default {
  name: 'iButton',
  props: ['label', 'icon', 'confirm', 'color', 'size'],
  data() {
    return {
    }
  },
  methods: {
    click() {
      if (!this.confirm || confirm(this.confirm)) this.$emit('click');
    }
  }
};
</script>
