import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';

import Browser from './Browser';
import Helper from './Helper';

const Converter = { to: {} };

Converter.to.csv = (items, attributes, filename) => {
    let csv = "data:text/csv;charset=utf-8,";

    csv += attributes.map(f => f.name).join(',') + "\r\n";

    items.forEach(item => {
        csv += '"' 
               + attributes
                 .map(f => ('' + Helper.get(item, f.name, '')).replace(/"/g, '\\"'))
                 .join('","') 
              + '"\r\n';
    });
    Browser.download(csv, filename);
}

Converter.to.xlsx = (items, attributes, filename) => {

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'TunisieRecyclage';
    workbook.created = new Date();
 
    const worksheet = workbook.addWorksheet('Items', {
        views: [ {state: 'frozen', xSplit: 0, ySplit:1} ]
    });

    // identify columns
    worksheet.columns = attributes.map(f => {
        return { 
            header: f.label || f.name, 
            key: f.name, 
            width: f.width ? f.width/8 : 15
        };
    });

    // header with labels
    const header = worksheet.getRow(1);
    header.height = 40;
    header.style = {font:{bold: true, name: 'Comic Sans MS'}};
    attributes.forEach(f => {
        header.getCell(f.name).value = f.label || f.name;
    })


    // the data
    for (let i=0; i<items.length; i++) {
        const row = worksheet.getRow(i+2);
        attributes.forEach(f => {
            row.getCell(f.name).value = Helper.get(items[i], f.name, '');

            switch (f.name) {
                case 'amount':
                case 'stamp':
                    row.getCell(f.name).numFmt = '# ##0.000;';
                    break;
            }
        })
    }

    // trigger download
    workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, filename);
    });
}



export default Converter;
