<template>
  <div id="app">
    <div v-if="updateExists" id="updateApp">
+      An update is available
       <button text @click="refreshApp">
         Update
       </button>
    </div>
    <MainMenu />
    
    <!-- global error display -->
    <iError 
      v-if="$store.state.error" 
      @close="$store.state.error = false"
      :error="$store.state.error"
      >
    </iError>
    <main>
      <keep-alive>
        <router-view/>
      </keep-alive>
    </main>
  </div>
</template>


<script>

import update from './mixins/update';
import MainMenu from '@/views/layout/MainMenu'

export default {
  name: 'App',
  mixins: [update],
  components: {
    MainMenu
  }
}

</script>


<style lang="scss">
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

html, body, #app {
  height: 100%;
}

#app {
  background-color: $bodyBgColor;
}



#app > main {
  position: absolute;
  top: $mainHeaderHeight; left: 0; right: 0; bottom: 0;
  overflow: auto;

  & > * {
    position: absolute;
    left: 0;top: 0;right: 0;bottom: 0;
    padding: 20px;
  }
}


#updateApp {
  position: fixed;
  background: orange;
  padding: 7px;
  top: 20px;
  width: 200px;
  z-index: 100;
  left: 50%;
  text-align: center;
  margin-left: -100px;
}
</style>