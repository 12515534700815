import Model from './Model';
const E = (attributes) => Model.extractFields(Compta, attributes);

const comptaTypes = {
    'in': {
        'cotisation': 'Participation Collecte',
        'vente': 'Vente matières',
        'vente_articles': 'Vente d\'articles',
        'vente_transport': 'Vente matériel de transport',
        'don': 'Don',
        'adhesion': 'Adhésion',
        'prêt': 'Prêt',
        'formation': 'Formation',
        'in_remboursement': 'STEG & SONED',
        'in_prestation_service': 'Prestation de Service',
    },
    'out': {
        'achat': 'Achat',
        'assurances': 'Assurances',
        'camion-achat': 'Camion / Achat',
        'camion-assurance': 'Camion / Assurance',
        'camion-entretien': 'Camion / Entretien',
        'camion-entretien-fuel': 'Camion / Entretien / Fuel',
        'camion-entretien-lavage': 'Camion / Entretien / Lavage',
        'camion-réparation': 'Camion / Réparation',
        'camion-taxe': 'Camion / Taxe',
        'salaire': 'Salaire',
        'salaire-pret': 'Prêt sur Salaire',
        'remboursement': 'Remboursement',
        'location': 'Location',
        'paprasse': 'Paperasse',
        'paprasse-cnss': 'CNSS',
        'paprasse-taxes': 'Impôts & Taxes',
        'paprasse-general': 'Frais généraux',
        'paprasse-utility': 'STEG & SONED',
        'paprasse-printer': 'Frais d\'impression',
        'achat-actions': 'achat de matériel pour actions',
        'achat-transport': 'achat de matériel de transport',
        'frais-equipement': 'Frais équipement local',
        'frais-comm': 'Frais de communication',
        'out_prestation_service': 'Prestation de Service',
    },
};

const Compta = {
    api: '/comptas',
    name: 'Compta',
    attributes: [
        { name: "legal_date", type: 'date', label: "Date légale", width: 90 },
        { name: "project.name", label: "Projet" },
        { 
            name: "kind", 
            label: "ES", 
            choices: ['in', 'out'], 
            tag: "compta-kind",
            width: 80 
        },
        {
            name: 'method',
            label: 'Méthode',
            choices: { 'cash': 'Espèces', 'cheque': 'Chèque', 'transfert': 'Transfert', 'other': 'Autre ...' },
        },
        { name: "type", label: "Type", choices: comptaTypes, width: 80 },
        { name: "label", label: "Intitulé", width: 260 },
        { name: "owner.name", label: "Vis à vis", width: 200 },
        { name: "cheque", label: "Chèque" },
        { name: "amount", type: "currency", label: "Montant", width: 130 },
        { name: "amount_ttc", type: "currency", label: "Montant TTC", width: 130 },
        { name: "tva", type: "percentage", label: "TVA (%)", width: 100 },
        { name: "computed_tva", type: "currency", label: "TVA", width: 100 },
        { name: "stamp", type: "currency", label: "Timbre", width: 90 },
        { name: "invoice_number", label: "Facture #" },
    ],
}

Compta.tableAttributes = E([
    "legal_date", "project.name",
    "kind", "type", 
    "label", "owner.name",  
    "method", "cheque", "amount", "tva", "computed_tva", "stamp", "amount_ttc", "invoice_number",
]);

Compta.process = (item) => {
    if (item.tva) item.computed_tva = item.amount * item.tva  / 100;
};


export default Compta;
