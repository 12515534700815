<template>
  <div class="model-index">

    <!-- grid -->
    <iGridView 
      :items="items" 
      :model="model"
      :defaultSort="model.defaultSort || ''"
      @itemclicked="viewItem"
      @itemupdateclicked="updateItem"
      @itemdeleted="deleteItem"
    >
      <template v-if="status === 'loading'" v-slot:prepend>
        <div class="info big">Chargement des données en cours ...</div>
      </template>
    </iGridView>
  
    <!-- view -->
    <ModelView 
      v-if="viewedItem" 
      :item="viewedItem"
      :model="model"
      @delete="deleteItem(viewedItem)"
      @update="updateItem(viewedItem)"
      @close="viewedItem = null" />

    <!-- edit -->
    <iModal v-if="editedItem" @close="editedItem = null;">
      <template v-slot:header>
        <h1><fa-icon icon="pen"/> Edition de {{mToString(editedItem)}}</h1>
      </template>
      <iForm 
        :item="editedItem"
        :attributes="model.editAttributes || model.attributes"
        :errors="editedItemErrors"
        @submit="saveItem"
        ></iForm>
    </iModal>
  </div>
  
</template>

<script>

import Model from "@/models/Model";
import Helper from "@/helpers/Helper";
import ModelView from "@/views/ModelView";
import model from '@/mixins/model';

export default {
  name: "ModelIndex",
  props: ['model'],
  mixins: [model],
  components: {
    ModelView,
  },
  filters: {
    currency: function (value) {
      return value.toFixed(3);
    }
  },
  data() {
    return {
      status: false,
      items: [],
      viewedItem: null,
      editedItem: null,
      editedItemErrors: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.status = 'loading';
      this.items = [];

      Model.loadAll(this.model).then(j => {
        this.status = false;
        if (!j.config || j.config.url !== this.model.api) {
          console.warn('Skipped updating');
          return;
        }
        this.items = j.data;
      });
    },

    viewItem(item) {
      Model.loadOne(this.model, item.id)
      .then((j) => {
        this.viewedItem = j.data;
      });
    },

    updateItem(item) {
      Model.loadOne(this.model, item.id)
      .then((j) => {
        this.editedItem = j.data;
        this.viewedItem = null;
      });
    },

    deleteItem(item) {
      this.$api.delete(`${this.model.api}/${item.id}`)
      .then(j => {
        this.items = this.items.filter(i => i !== item);
        this.viewedItem = null;
      })
    },
    saveItem(data) {
      Model.saveOne(this.model, this.editedItem.id, data)
      .then((j) => {
          const index = this.items.findIndex(item => item.id === this.editedItem.id);
          this.$set(this.items, index, j.data);
          this.editedItem = null;
          this.editedItemErrors = [];
      })
      .catch((err) => {
        if (err.response.status === 422) { // Yii2 validation error code
          this.editedItemErrors = err.response.data;
        }
      });
    },    
  },
  watch: {
    $route(to, from) {
      // getting here ?
      if (to.matched && Helper.get(to.matched[0], 'props.default.model')) {
        this.loadData();
      }
    }
  }
};
</script>
